@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

.body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  max-width: 1280px;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: -10px 4px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1280px) and (min-width:1152px) {
  .body-container{
    max-width: 1152px !important 
  }
}
@media only screen and (max-width: 1151px) and (min-width:1024px) {
  .body-container{
    max-width: 1024px !important 
  }
}
@media only screen and (max-width: 1023px) and (min-width:768px) {
  .body-container{
    max-width: 768px !important 
  }
}
@media only screen and (max-width: 767px) {
  .body-container{
    flex-direction: column !important;
    box-shadow: none !important;
    margin: 0px !important;
    border-radius: 0px !important;
  }
  .body-container .card-1::after{
    display: none;
  }
  .card-body-1,.card-body-2{
    grid-template-columns: 1fr 1fr !important;
  }
  .card-header-1{
    font-size: 18px !important;
    height: 80px !important;
  }
  .card-header-2{
    font-size: 18px !important;
    height: 80px !important;
  }
  .card-widget-code{
    font-size: 16px !important;
  }
  .card-widget-name{
   
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .newsletter-container .container{
    flex-direction: column !important;
  }
}
.card-1 {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  background: #FFF;
}
.card-1::after{
  position: absolute;
  content: " ";
  width: 2px;
  height: 100%;
  top: 0px;
  right: 0px;
  background-color: #80808041;
}
.card-2 {
  width: 100%;
  background-color: #ffffff;
  background: #FFF;
}

.card-header-1 {
  color: #000;
  font-family: 'SF Pro Display';
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 105px; 
}
.card-header-2 {
  color: #000;
  font-family: 'SF Pro Display';
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 105px; 
}

.card-header>* {
  padding: 1rem;
}

.card-body-1 { 
    display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      background: rgba(110, 203, 160, 0.20);
      padding-bottom: 30px !important;
}
.card-body-2 { 
    display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    background: rgba(255, 35, 35, 0.20);
    padding-bottom: 30px !important;
}

.card-widget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.card-widget-code {
  color: #000;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card-widget-price-gainer{
  color: #2a3a32;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.card-widget-price-loser{
  color: #5c2d2d;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.card-widget-name {
color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-widget-percentage {
  color: #6ECBA0;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.card-widget-percentage svg{
  transform: scale(0.5);
}

.card-widget-percentage-2 {
  color: #FF4D4D;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .card-widget-percentage-2 svg{
    transform: scale(0.5);
  }
.newsletter-container {
  width: 100%;
  flex-shrink: 0;
  padding-top: 64px;
  padding-bottom: 64px;
  background: #273048;
}
.newsletter-container .container{
  max-width: 1280px ;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
gap: 25px;
}
.newsletter-title{
  font-size: 30px;
  font-weight: 400;
  color: #fff;

}

.card-widget-name-container{
  margin-bottom: 30px;
}

.newsletter-form { 
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.newsletter-text {
  font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;
}
.specialButton{
  background-color: #fff;
  width: 100%;
  height: 72px;
  display: flex;
  border-radius: 36px;
  overflow: hidden;
}
.specialButton   input{
  border: none !important;
  outline: none !important;
  width: 100%;
  padding-left: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #0A142F !important;
}
.specialButton button{
   border: none !important;
  outline: none !important;
  width: auto;
  background-color: transparent !important;
}
.specialButton input::placeholder{
  font-size: 18px;
  font-weight: 500;
  color: #0A142F !important;
}