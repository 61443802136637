@import url('https://fonts.googleapis.com/css?family=Inter');

.header {
  position: relative;
  background-color: #273048;
  padding: 10px 0;
  flex-direction: row;
  width: 100%;
  z-index: 99;
}
.hamburgerMenu{
  position: fixed;
  transition: all 0.8s;
  bottom: 100%;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
}
.hamburgerMenu.active{
  background-color: #273048;
  width: 100%;
  height: 100%;
  top: 0px;
  padding-top: 122px;
  left: 0px;
  position: fixed;
  z-index: 8 !important;
  bottom: 0% !important;
  transition: all 0.2s;
}

.hamburgerMenu ul{
  position: relative;
  list-style-type: none;
  justify-content: center;
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column !important;
  gap: 10%;
}

.hamburgerMenu ul li{
  text-align: center;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column !important;
}
.hamburgerMenu ul li a{
  font-size: 30px;
  color: #fff !important;
  font-weight: 400;
  font-family: Inter;
  transition: all;
  display: flex;
  flex-direction: column !important;
  cursor: pointer;
}
.hamburgerMenu ul li a::after{
  transition: all 0.7s;
  width: 0%;
  position: relative;
  height: 4px;
  background-color: #ffffff67;
  content: " ";
}

.hamburgerMenu ul li a:hover::after{
  transition: all 0.7s;
  width: 100%;
}



.header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding-top: 27px;
  padding-bottom: 27px;
  align-items: center;
  max-width: 1280px;
  margin: auto;
  position: relative;
  z-index: 9 !important;
}

.header .logo {
  /* font-family: "BorsaKulesi", sans-serif; */
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 10px;
}

.header .navbar-brand {
  color: #FFF;
    font-family: 'Inter';
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.header .btn { 
    background-color: #FFF;
    flex-shrink: 0;
    color: #273047;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    backdrop-filter: blur(75px);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .header .btn { 
    font-size: 14px !important;
}
  .header .logo {
    width: 80%; /* Adjust the width as needed for smaller screens */
    max-width: 300px; /* Set a maximum width for the logo */
  }
}
