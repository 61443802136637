.footer {
  background-color: #0A142F;
  color: #ffffff;
  padding: 20px 0;
  border-top: 1px solid #333333;
}

.footer .container {
  text-align: center;
  max-width: 1280px;
  margin: auto;
}

.footer .logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .footer .logo-container {
    flex-direction: column !important;
    gap: 25px !important;
    margin-bottom: 40px;  
  }
  .footer .website-logo{
    margin-top: 40px;
    margin-bottom: 40px;
    min-width: 75% !important;
  }
  .footer .sponsor-logo-container{
    width: 75px !important;
  }
}

.footer .website-logo,
.footer .sponsor-logo {
  width: 240px;
  height: auto;
}

.footer .text {
  text-align: center;
  text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;
    color: #ffffff;
    margin-top: 50px;
}

.footer .logo-container .sponsor-logo-container {
  width: 100px; /* Adjust the width as needed */
  height: auto;
}


.footer .footer-menu { 
  display: flex;
  flex-direction: row;
}

.footer .menu-items {
  margin-left: 29px;
  margin-right: 29px;
}